const personalDetails = {
  firstName: 'Gautham',
  lastName: 'A K',
  role: 'Full Stack Developer',
  mail: 'gautham.ak@gauthamkanagaraj.com',
  linkedin: 'https://www.linkedin.com/in/gautham-a-k-bb06a8194/',
  github: 'https://github.com/gauthamkanagaraj'
};

const about = [{
  title: 'About Me',
  description: 'A Passionate Programmer who has been actively involved in Full Stack Web Development for the past 3 years.\nI am a dedicated learner and also an independent researcher exploring the different fields of computing.',
  id: 1
}];

const experience = [
    {
      title: 'Experience: Jul 2021 - Present',
      description: 'Organization: Tata Consultancy Services\n\nRole: Systems Engineer\n(Software Development)\n\nFrom: Jun 2021 to Present\n\nDescription: Spearheaded the development of multiple enterprise production-ready applications along with a configuration management app using state-of-the-art Spring Cloud Technology Suite.',
      id: 2
    },
    {
      title: 'Experience: Jan - Jun 2021',
      description: 'Organization: Tata Consultancy Services\n\nRole: Assistant Systems Engineer\n(Software Development)\n\nFrom: Jan 2021 to Jun 2021\n\nDescription: Modernized commercial applications from a legacy tech stack to a cloud-ready infrastructure along with browser compatibility extension and security vulnerability remediation of the codebase.',
      id: 3
    }
];

const achievements = [{
  title: 'Achievements',
  description: '1. Promoted within a six-month time frame for exceeding all technical goals and incorporating company culture.\n\n2. Received the Beyond Excellence Award twice for exceeding all expectations and demonstrating passion with diligence.\n\n3. Received the Special Initiative Award for exhibiting creativity and innovation.\n\n4. Acquired the Best Performance Award for outstanding contributions to the organization and for being an inspiring role model to my colleagues.',
  id: 4
}];

const education = [{
  title: 'Education',
  description: 'BE Mechatronics Engineering\nThiagarajar College of Engineering\nCGPA - 8.86',
  id: 5
}];

const publications = [{
  title: 'Publications',
  description: '1. Machine Learning and IoT Based Ultrasonic Humidification Control System for Longevity of Fruits and Vegetables in Springer Book Series - Algorithms for Intelligent Systems\n\n2. Machine Learning based Tutor Ward System for Cognitive Learning Style Management in Journal for Engineering Education [Approved for Publication]',
  id: 6
}];

const skills = [{
  title: 'Skills',
  description: 'Java | Spring Framework | Spring Cloud |\nJavascript | Python | Linux/Unix | HTML5 | CSS3 |\nReact | Google Cloud | SQL | Docker | Kubernetes |\nMicroservices | Data Structures | Algorithms',
  id: 7
}];

export {personalDetails, about, experience, achievements, education, publications, skills}